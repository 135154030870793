<template>
  <div>
    <div class="app_update_button_container">
      <button class="update-alert" v-if="updateAvailable" @click="refreshApp">
        <md-icon>update</md-icon>
        New update available! Click to update.
      </button>
    </div>
    <div
      v-if="Object.keys($store.state.user).length || userEmail"
      class="user-header"
    >
      <div class="user-setting">
        <md-button @click="showMenu = !showMenu" class="text-btn user"
          >{{ $store.state.user.fullname
          }}<md-icon style="color: rgb(255 255 255 / 54%) !important"
            >arrow_drop_down</md-icon
          ></md-button
        >
        <div v-if="showMenu" class="menu">
          <a
            v-if="$store.state.user.isSuperAdmin || $store.state.user.isAdmin"
            href="/"
          >
            <md-icon style="color: rgb(255 255 255 / 54%) !important"
              >home</md-icon
            >
            Orders
          </a>
          <!-- <a v-if="$store.state.user.isSuperAdmin" href="/user-activities">
            <md-icon style="color: rgb(255 255 255 / 54%) !important"
              >insert_chart</md-icon
            >
            Activity Log
          </a> -->
          <a v-if="$store.state.user.isSuperAdmin" href="/users">
            <md-icon style="color: rgb(255 255 255 / 54%) !important"
              >group</md-icon
            >
            Users
          </a>
          <a style="cursor: pointer" @click="logout">
            <md-icon style="color: rgb(255 255 255 / 54%) !important"
              >logout</md-icon
            >
            Logout
          </a>
        </div>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { auth, db } from "@/config/firebaseInit.js";

export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateAvailable: false,
      updateExists: false,
      userEmail: "",
      showMenu: false,
    };
  },
  async created() {
    document.addEventListener("swUpdate", this.showUIUpdate, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // To prevent infinite loop refresh
        if (this.refreshing) {
          return;
        }

        this.refreshing = true;
        window.location.reload();
      });
    }
    await this.checkIfLoggedIn();
    this.showPendingOrderAlert();
  },
  methods: {
    async checkIfLoggedIn() {
      if (auth.currentUser && !Object.keys(this.$store.state.user).length) {
        await this.$store
          .dispatch("getUserData", auth.currentUser.uid)
          .then(() => {
            window.location.reload();
          });
        this.userEmail = auth.currentUser.email;
      }
    },
    logout() {
      auth.signOut().then(() => {
        this.userEmail = false;
        this.showMenu = false;
        this.$store.dispatch("signOut");
        this.$router.replace("login");
      });
    },
    showUIUpdate(e) {
      console.log(e);
      console.log("UI has been activated and should show alert");
      this.registration = e.detail;
      this.updateAvailable = true;
      Swal.fire({
        title: "New Update!",
        text: "New update available! Click Update to refresh. Remember to save any changes you made before continuing",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "UPDATE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          this.refreshApp();
        } else if (result.isDismissed) {
          if (this.updateExists)
            setTimeout(() => {
              this.showUIUpdate(e);
            }, 300000);
        }
      });
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    showPendingOrderAlert() {
      const notifications_pending_orders = db.doc(
        "notifications/pending_orders"
      );
      if (auth.currentUser && !Object.keys(this.$store.state.user).length) {
        this.$bind("notifications", notifications_pending_orders).then(
          (res) => {
            if (res.orders.length) {
              if (res.showNotifications) {
                const orders = res.orders.join(",");
                Swal.fire(
                  "Unfinised Orders",
                  `You have orders still in pending:  ${orders}`,
                  "warning"
                ).then(() => {
                  notifications_pending_orders.update({
                    showNotifications: false,
                  });
                });
              }
            }
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.app_update_button_container {
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
}
.update-alert {
  width: 100%;
  background-color: #25bf6e;
  border: none;
  padding: 10px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  font-family: "Roboto";
  z-index: 10;
  justify-content: center;
}

.update-alert img {
  height: 30px;
}

.update-alert p {
  margin: 0 0 0 20px;
  font-size: 18px;
  font-family: AzoSans;
}
</style>

<style src="../public/css/main.css"></style>
