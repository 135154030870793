<template>
  <div class="login">
    <div class="contain">
      <img class="logo" src="@/assets/logo.png" />
      <!-- <p class="hello" v-if="user.start">Hello {{ user.start.full_name }},</p> -->
      <h1>Login to your account below.</h1>

      <form @submit.prevent="login">
        <div class="question">
          <p class="title">Pick up where you left off!</p>
          <div class="inputs">
            <div class="input full">
              <label>Email Address</label>
              <input ref="email" type="email" v-model="user.email" />
            </div>
            <div class="input full">
              <label>Password</label>
              <input ref="password" type="password" v-model="password" />
            </div>
            <div class="btns">
              <button type="submit" class="btn">Login</button>
            </div>
            <button @click="forgotPassword()" class="btn text-btn back">
              forgot password
            </button>
            <p v-if="errors.length" class="errors">
              Please enter a
              <span v-for="(error, index) in errors" :key="index">
                {{ error }}{{ index + 1 == errors.length ? "." : ", " }}
              </span>
            </p>
            <p class="errors">{{ login_error }}</p>
            <p v-if="password_reset_success" class="success">
              Password Reset Sent!
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { auth } from "@/config/firebaseInit";
import firebase from "firebase";

export default {
  name: "login",
  data() {
    return {
      user: {
        email: null,
      },
      password: null,
      errors: [],
      login_error: null,
      password_reset_success: false,
    };
  },
  watch: {
    login_error(val) {
      if (val) {
        setTimeout(() => {
          this.login_error = "";
        }, 3000);
      }
    },
  },
  methods: {
    login() {
      if (this.validate()) {
        let vm = this;
        firebase
          .auth()
          .signInWithEmailAndPassword(this.user.email, this.password)
          .then((data) => {
            let uid = data.user.uid;
            this.$store.dispatch("getUserData", uid).then(() => {
              this.$router.push("/");
            });
            // db.collection("administrator")
            //   .where("uid", "==", uid)
            //   .get()
            //   .then((snap) => {
            //     let user = snap.docs[0].data();
            //     user.id = snap.docs[0].id;
            //     // this.$store.commit("setNewUser", user);
            //     // this.$store.commit("setApplicationApprovalPhase", user.status);
            //     this.$router.push("/");
            //   })
            //   .catch(function (error) {
            //     vm.login_error = error.message;
            //   });
          })
          .catch(function (error) {
            vm.login_error = error.message;
          });
      }
    },
    validate() {
      let valid = true;
      this.errors = [];
      this.error = null;
      if (this.user.email) {
        if (!this.validateEmail()) {
          valid = false;
          this.errors.push("valid Email");
        }
      } else {
        valid = false;
        this.errors.push("valid Email");
      }

      if (!this.password) {
        valid = false;
        this.errors.push("valid Password");
        this.$refs.password.classList.add("error");
      } else {
        this.$refs.password.classList.remove("error");
      }
      return valid;
    },
    validateEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.user.email.toLowerCase())) {
        this.$refs.email.classList.remove("error");
        return true;
      } else {
        this.$refs.email.classList.add("error");
        return false;
      }
    },
    forgotPassword() {
      let valid = true;
      this.errors = [];
      if (this.user.email) {
        if (!this.validateEmail()) {
          valid = false;
          this.errors.push(
            "valid Email so we can send you an reset password link"
          );
        }
      } else {
        valid = false;
        this.errors.push(
          "valid Email so we can send you an reset password link"
        );
      }
      if (valid) {
        let vm = this;
        auth
          .sendPasswordResetEmail(this.user.email)
          .then(function () {
            vm.password_reset_success = true;
          })
          .catch(function (error) {
            vm.login_error = error.message;
            console.log(error);
          });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // if (
    //   to.params.bypass_code == "D!S1504" ||
    //   to.params.bypass_code == "client"
    // ) {
    //   return next();
    // } else {
    //   fetch("https://api.ipify.org?format=json")
    //     .then((response) => response.json())
    //     .then((data) => {
    //       if (data.ip == process.env.VUE_APP_APP_IP) next();
    //       else next({ name: "Error404" });
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }
    return next();
  },
};
</script>

<style scoped lang="css">
.login {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contain {
  max-width: 650px;
  background: white;
  padding: 5%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1 {
  margin-bottom: 10px;
}
.hello {
  margin-bottom: 10px;
}
.errors {
  margin-top: 20px;
  color: red !important;
}
.success {
  width: 100%;
  margin-top: 20px;
  color: #5bc575;
}
.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
}

.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  text-align: center;
}

p.title {
  font-size: 17px !important;
  padding: 2% 0;
}
input[type="email"] {
  border: none;
  border-bottom: 1px solid #0024d6;
  margin-bottom: 5%;
  width: 250px;
}
input[type="password"] {
  border: none;
  border-bottom: 1px solid #0024d6;
  margin-bottom: 5%;
  width: 250px;
}
.input.full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
button.btn {
  background-color: #100e31 !important;
  color: white !important;
  font-weight: 700 !important;
  border: none !important;
  width: 100%;
  padding: 10px 25px;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
}
button.text-btn.back {
  background-color: transparent !important;
  color: #0024d6 !important;
  font-weight: 700 !important;
  border: none !important;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 3%;
}
.btns {
  margin-top: 5%;
}
label {
  font-weight: 700;
}
</style>
