var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pagination",class:_vm.paginationClass},[_c('li',{staticClass:"page-item prev-page",staticStyle:{"margin-top":"-3px"}},[_c('a',{staticClass:"page-link",class:{ disabled: _vm.value === 1, 'no-arrows': _vm.noArrows },attrs:{"aria-label":"Previous"},on:{"click":_vm.prevPage}},[_c('i',{staticClass:"fas fa-angle-double-left",class:{ disabled: _vm.value === 1, 'no-arrows': _vm.noArrows }})])]),_vm._v(" Page: "),_vm._l((_vm.range(_vm.minPage, _vm.maxPage)),function(item){return _c('li',{key:item,staticClass:"page-item",class:{ active: _vm.value === item },staticStyle:{"margin-top":"-3px"}},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.changePage(item)}}},[_vm._v(_vm._s(item))])])}),_vm._v(" of: "+_vm._s(_vm.totalPages)+" "),_c('li',{staticClass:"page-item page-pre next-page",staticStyle:{"margin-top":"-3px"}},[_c('a',{staticClass:"page-link",class:{
        disabled: _vm.value === _vm.totalPages,
        'no-arrows': _vm.noArrows,
      },attrs:{"aria-label":"Next"},on:{"click":_vm.nextPage}},[_c('i',{staticClass:"fas fa-angle-double-right",class:{
          disabled: _vm.value === _vm.totalPages,
          'no-arrows': _vm.noArrows,
        }})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }