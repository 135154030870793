<template>
  <div>
    <label>{{ label }}</label>
    <div class="upload_holder">
      <transition name="fade">
        <div
          v-if="!media.length && !load && $store.state.user.isAdmin"
          class="upload_click"
          @click="$refs.upload.click()"
        >
          <md-icon>present_to_all</md-icon>
          <p>
            You currently have no media uploaded. Click here to upload media.
          </p>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-if="!media.length && !$store.state.user.isAdmin"
          class="upload_click"
        >
          <md-icon>present_to_all</md-icon>
          <p>There currently no media uploaded by admin.</p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!media.length && load" class="loading">
          <md-icon class="rotate">autorenew</md-icon>
          <p>loading...</p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="media.length && !load" class="image_success">
          <div class="image_wrapper" v-for="(image, i) in media" :key="i">
            <img
              style="
                cursor: -moz-zoom-in;
                cursor: -webkit-zoom-in;
                cursor: zoom-in;
              "
              @click="openImageModal(image.url)"
              :src="image.url"
              :alt="image.name"
            />
            <md-button
              v-if="$store.state.user.isAdmin"
              @click="removeImage(image, i)"
              class="md-accent md-right md-icon-button"
            >
              <md-icon> cancel </md-icon>
              <md-tooltip>Remove Image</md-tooltip>
            </md-button>
            <!-- <video v-if='media_type == "video"' :src="media"></video> -->
          </div>
        </div>
      </transition>

      <input
        ref="upload"
        multiple
        type="file"
        :accept="'image/*'"
        class="hide_upload"
        @change="uploadImages"
      />
    </div>
    <div
      v-if="media.length && !load && $store.state.user.isAdmin"
      class="upload_click"
      style="cursor: pointer"
      @click="$refs.upload.click()"
    >
      <p style="color: blue !important">Upload Images</p>
    </div>

    <Modal
      @close="
        () => {
          (showImageModal = false), (imageView = null);
        }
      "
      :showSaveBtn="false"
      :showDialog="showImageModal"
    >
      <template v-slot:modalContent>
        <div class="image-container">
          <img style="width: 100%" :src="imageView" alt="" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { storage } from "@/config/firebaseInit";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";

export default {
  components: { Modal },
  props: {
    label: {
      type: String,
      default: "",
    },
    media: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      load: false,
      imageURLs: [],
      imageView: null,
      showImageModal: false,
    };
  },
  methods: {
    openImageModal(url) {
      this.imageView = url;
      this.showImageModal = true;
    },
    removeImage(image, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let imageRef = storage.ref(
            `failed_items_images/${this.item.ItemNum}/${image.name}`
          );
          // Delete the file
          imageRef
            .delete()
            .then(() => {
              this.$emit("removeImage", image, index);
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log(error);
            });
        }
      });
    },
    uploadImages(e) {
      let vm = this;
      this.upload = null;
      vm.load = null;
      setTimeout(function () {
        vm.load = true;
      }, 500);

      let images = Object.values(e.target.files);

      let promises = images.map((media) => {
        let reader = new FileReader();

        reader.readAsDataURL(media);
        return (reader.onload = (e) => {
          let storageRef = storage.ref(
            `failed_items_images/${this.item.ItemNum}/${media.name}`
          );
          let uploadTask = storageRef.put(media);

          return uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              vm.logoLoad = false;
              alert("could not upload images, please try again.");
            },
            (snapshot) => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.$emit("uploadedImages", {
                  name: media.name,
                  url: downloadURL,
                });
                vm.load = null;
                setTimeout(function () {
                  vm.load = false;
                }, 500);
                return downloadURL;
              });
            }
          );
        });
      });
    },
  },
};
</script>

<style lang="css" scoped>
.upload_holder {
  width: 100%;
  padding: 30px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_wrapper {
  margin-right: 10px;
  position: relative;
  display: inline-flex;
}
.text_btn {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin: 0;
  background: red;
  padding: 5px 10px;
}
.hide_upload {
  position: absolute;
  z-index: -2;
}

.upload_click,
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload_click .md-icon {
  font-size: 115px !important;
  opacity: 0.5;
  margin: 30px !important;
}

.md-right {
  position: absolute !important;
  right: -25px !important;
  top: -20px !important;
}

.upload_click p {
  cursor: pointer;
  line-height: 1.3;
  max-width: 300px;
  text-align: center;
  opacity: 0.5;
  margin-top: 30px;
  margin-bottom: 0;
}

.rotate {
  font-size: 40px !important;
  -webkit-animation: loading 1s infinite linear;
}

.success p {
  text-align: center;
  margin-bottom: 0;
}

.success img {
  width: 100%;
  max-height: 100%;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
